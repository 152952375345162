import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AuthGuard } from '../core/guards/auth.guard';
import { LoaderService } from '../core/services/loader.service';
import { UserService } from '../core/authentication/user.service';
import { AppConfig } from '../core/config/app.config';
import { ToasterService } from '../core/services/toaster.service';
import { HttpService } from '../core/http/http.service';
import { AppErrorHandlerService } from '../core/services/app-error-handler.service';
import { OrdersService } from '../core/services/orders.service';
import { CustomLoggerService } from './../logger.service';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [HttpService, AppErrorHandlerService, AuthGuard, LoaderService, UserService, AppConfig, CustomLoggerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    ToasterService,
    OrdersService],
})
export class CommonServiceModule { }
