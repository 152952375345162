import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-deliverydate',
  templateUrl: './dialog-deliverydate.component.html',
  styleUrls: ['./dialog-deliverydate.component.scss']
})
export class DialogDeliverydateComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data);
  }

  ngOnInit(): void {
  }

}
