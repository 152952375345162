import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderProductModel } from 'src/app/core/models/order-product.model';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/core/config/app.config';
import { CallForPriceComponent } from 'src/app/shared/components/call-for-price/call-for-price.component';
import { MatTableDataSource } from '@angular/material/table';
import { Order, Orderline } from 'src/app/core/models/response';
import { CustomLoggerService } from './../../logger.service';

@Component({
    selector: 'app-dialog-order-detail',
    templateUrl: './dialog-order-detail.component.html',
    styleUrls: ['./dialog-order-detail.component.scss']
})
export class DialogOrderDetailComponent implements OnInit {

    displayedColumn: string[];
    productList: MatTableDataSource<Orderline>;
    order: Order;
  stylesConfig:any={}


    constructor(public dialogRef: MatDialogRef<DialogOrderDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Order, public dialog: MatDialog,
        private translate: TranslateService,
        private logger: CustomLoggerService,
    ) {
        this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    this.stylesConfig = localStorage.getItem('styles')  ? JSON.parse(localStorage.getItem('styles')) :{}

    }

    ngOnInit() {
        if (this.getCallForPrice() === 1) {
            this.displayedColumn = ['description', 'productid', 'price', 'weight', 'quantity', 'amount'];
        } else {
            this.displayedColumn = ['description', 'productid', 'price', 'weight', 'quantity'];
        }
        this.order = this.data;
        this.productList = new MatTableDataSource(this.order.orderline);
        // this.logger.log(`dialog-order-detail.component getCallForPrice: ${JSON.stringify(this.order.orderline)}`);
    }

    onClose(): void {
        this.dialogRef.close(null);
    }

    clickedCallForPrice() {
        // this.logger.log(`dialog-order-detail.component getCallForPrice: ${JSON.stringify(this.order.customerUserSupplier._id)}`);
        const dialogRef = this.dialog.open(CallForPriceComponent, {
            width: '470px',
            data: this.order.customerUserSupplier._id
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.logger.log(`dialog-order-detail.component afterClosed: ${JSON.stringify(result)}`);
            if (result && result != null) {
            }

        });
    }

    getCallForPrice(): number {
        return AppConfig.settings.CallForPriceWhenZero;
    }

    isCustomColor(color: string): boolean {
      // List of Angular Material predefined color names
      const materialColors = ['primary', 'accent', 'warn', 'default'];
      return !materialColors.includes(color);
    }
}
