<h1 mat-dialog-title>
  <span> {{ "Common.ChangePassword.Title" | translate }} </span>
  <button
    mat-icon-button
    color="warn"
    style="float: right"
    (click)="onClose()"
    matTooltip="Close"
  >
    <mat-icon>cancel</mat-icon>
  </button>
</h1>
<mat-divider></mat-divider>
<br />
<form class="example-form" [formGroup]="changePasswordForm">
  <div mat-dialog-content formGroupName="passwordGroup">
    <mat-form-field class="dialog-form-full-width">
      <input
        matInput
        placeholder="{{
          'Common.ChangePassword.OldPasswordPlaceholder' | translate
        }}"
        type="password"
        name="oldPassword"
        formControlName="oldPassword"
        required
        [ngClass]="{
          'is-invalid':
            (changePasswordForm.get('passwordGroup.oldPassword')?.touched ||
              changePasswordForm.get('passwordGroup.oldPassword')?.dirty) &&
            !changePasswordForm.get('passwordGroup.oldPassword')?.valid
        }"
      />
      <mat-error
        *ngIf="
          changePasswordForm.get('passwordGroup.oldPassword')?.errors?.required
        "
      >
        {{
          "Common.ChangePassword.OldPasswordRequireValidationMessage"
            | translate
        }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="dialog-form-full-width">
      <input
        matInput
        placeholder="{{
          'Common.ChangePassword.NewPasswordPlaceholder' | translate
        }}"
        type="password"
        name="password"
        formControlName="password"
        required
        [ngClass]="{
          'is-invalid':
            (changePasswordForm.get('passwordGroup.password')?.touched ||
              changePasswordForm.get('passwordGroup.password')?.dirty) &&
            !changePasswordForm.get('passwordGroup.password')?.valid
        }"
      />
      <mat-error
        *ngIf="
          changePasswordForm.get('passwordGroup.password')?.errors?.required
        "
      >
        {{
          "Common.ChangePassword.NewPasswordRequireValidationMessage"
            | translate
        }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="dialog-form-full-width">
      <input
        matInput
        placeholder="{{
          'Common.ChangePassword.ConfirmPasswordPlaceholder' | translate
        }}"
        type="password"
        name="confirmPassword"
        formControlName="confirmPassword"
        required
        [ngClass]="{
          'is-invalid':
            (changePasswordForm.get('passwordGroup.confirmPassword')?.touched ||
              changePasswordForm.get('passwordGroup.confirmPassword')?.dirty) &&
            !changePasswordForm.get('passwordGroup.confirmPassword')?.valid
        }"
      />
      <mat-error
        *ngIf="
          changePasswordForm.get('passwordGroup.confirmPassword')?.errors
            ?.required
        "
      >
        {{
          "Common.ChangePassword.ConfirmPasswordRequireValidationMessage"
            | translate
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div
    mat-dialog-actions
    style="
      height: 100%;
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center flex-end;
      align-items: center;
    "
  >
    <button
      mat-stroked-button
      color="primary"
      type="submit"
      (click)="submitChangePassword()"
    >
      <mat-icon>update</mat-icon>
      {{ "Common.ChangePassword.SubmitButtonText" | translate }}
    </button>
  </div>
</form>
