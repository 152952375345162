<h1 mat-dialog-title>User</h1>
<div mat-dialog-content>
  <div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>First Name</mat-label>
      <input matInput [(ngModel)]="data.firstname" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Last Name</mat-label>
      <input matInput [(ngModel)]="data.lastname" />
    </mat-form-field>
  </div>
  <div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="data.emailaddress" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="data.password" />
    </mat-form-field>
  </div>
  <div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>Role</mat-label>
      <mat-select [(ngModel)]="data.role">
        <mat-option value="ADMIN">ADMIN</mat-option>
        <mat-option value="USER">USER</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Customer Id</mat-label>
      <input matInput [(ngModel)]="data.customerId" />
    </mat-form-field>
    
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onClickOK()">OK</button>
</div>
