<h1 mat-dialog-title>
    <button mat-icon-button>
        <mat-icon color="accent">check_circle</mat-icon>
    </button>
    Order Confirmation
    Thank you for your order.
</h1>
<div mat-dialog-content>
    <mat-list>
        <mat-list-item>Submitted Successfully</mat-list-item>
        <mat-list-item>Order Number: {{data.CustomerPO}}</mat-list-item>
        <mat-list-item *ngIf="false">Estimated Delivery: {{data.deliverydate | date: 'MM/dd/yyyy'}}</mat-list-item>
    </mat-list>

    <button mat-flat-button color="accent" style="width: 100%; color: white" [routerLink]="['/orders']" routerLinkActive="active" [mat-dialog-close]="true">OK</button>
</div>
