import { map, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/throw';
import { HttpErrorModel } from '../models/http.error.model';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CustomLoggerService } from './../../logger.service';

@Injectable()
export class HttpService {
  constructor(
    private http: HttpClient,
    private logger: CustomLoggerService,
  ) { }

  public get<T>(url: string, options?: any): Observable<any | HttpErrorModel> {
    return this.http.get(url, options).pipe(catchError(this.handleHttpError));
  }

  public post<T>(
    url: string,
    body: string,
    options?: any
  ): Observable<any | HttpErrorModel> {
    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleHttpError));
  }

  public update<T>(
    url: string,
    itemToUpdate: any,
    options?: any
  ): Observable<any | HttpErrorModel> {
    return this.http
      .put(url, itemToUpdate, options)
      .pipe(catchError(this.handleHttpError));
  }

  public delete<T>(
    url: string,
    options?: any
  ): Observable<any | HttpErrorModel> {
    return this.http
      .delete(url, options)
      .pipe(catchError(this.handleHttpError));
  }

  //#region Helper Methods

  extractData(res: Response) {
    return res.json() || {};
  }

  private handleHttpError(error: any): Observable<HttpErrorModel> {
    // this.logger.log(`http.service handleHttpError: ${JSON.stringify(error)}`);
    const dataError = new HttpErrorModel();
    if (error.error) {
      // this.logger.log(`http.service This is client side error: ${JSON.stringify(error.error.message)}`);
      dataError.message = `Error: ${error.error.message}`;
    } else {
      // this.logger.log(`This is server side error:  Error Code: ${error.status},  Message: ${error.message}`);
      dataError.message = `Error Code: ${error.status},  Message: ${error.message}`;
    }
    return throwError(() => dataError);
  }
  //#endregion
}
