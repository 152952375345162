import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomLoggerService } from './../../logger.service';

@Component({
  selector: 'app-deleteuser',
  templateUrl: './deleteuser.component.html',
  styleUrls: ['./deleteuser.component.scss']
})
export class DeleteuserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteuserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private logger: CustomLoggerService,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    // this.logger.log("deleteuser.component onNoClick:")
    this.dialogRef.close();
  }

  onClickOK() {
    // this.logger.log(`deleteuser.component onClickOK: ${JSON.stringify(this.data)}`);
    this.dialogRef.close(this.data);
  }

}
