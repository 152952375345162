import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from 'src/app/core/config/app.config';
import { UserService } from 'src/app/core/authentication/user.service';
import { ChangePasswordModel } from 'src/app/core/models/change-password.model';
import { CustomLoggerService } from './../../../logger.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { HttpErrorModel } from 'src/app/core/models/http.error.model';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    private userService: UserService,
    private logger: CustomLoggerService,
    private orderService: OrdersService,
  ) {
    this.loaderService.display(false);
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
  }

  ngOnInit() {
    //   this.userForm = this.formBuilder.group({
    //     passwords: this.formBuilder.group({
    //         password: ['', [Validators.required]],
    //         confirm_password: ['', [Validators.required]],
    //     }, {validator: this.passwordConfirming}),

    // });
    this.changePasswordForm = this.fb.group({
      passwordGroup: this.fb.group(
        {
          oldPassword: ['', Validators.required],
          confirmPassword: ['', Validators.required],
          password: ['', Validators.required],
        },
        {
          /*validator: PasswordMatcherValidator*/
        }
      ),
    });
  }
  onClose() {
    this.dialogRef.close(null);
  }
  submitChangePassword() {
    if (this.changePasswordForm.valid) {
      const changePasswordModel: ChangePasswordModel =
        new ChangePasswordModel();
      changePasswordModel.username = localStorage.getItem('email') || '';
      changePasswordModel.existingPassword = this.changePasswordForm.get(
        'passwordGroup.oldPassword'
      )?.value;
      changePasswordModel.password = this.changePasswordForm.get(
        'passwordGroup.password'
      )?.value;
      if (
        changePasswordModel.password !==
        this.changePasswordForm.get('passwordGroup.confirmPassword')?.value
      ) {
        this.toasterService.showToaster(
          this.translate.instant(
            'Common.ChangePassword.ConfirmPasswordNotMatchValidationMessage'
          ),
          5000
        );
        return;
      }
      this.orderService.changePassword(changePasswordModel).subscribe({
        complete: () => { }, // completeHandler
        error: (err: HttpErrorModel) => {
          localStorage.clear();
          this.loaderService.display(false);
          // this.logger.error(`login.component An error occurred. Please try again: ${JSON.stringify(err)}`);
          this.toasterService.showToaster(
            'An error occurred. Please try again.',
            5000
          );
        },
        next: (data: any) => {
          this.toasterService.showToaster(
            'Password Changed Successfully',
            5000
          );
        },
      });
      // this.userService.changePassword(changePasswordModel)
      //   .subscribe({
      //     complete: () => { },
      //     error: (err) => {
      //       // this.logger.error(`changepassword.component An error occurred. Please try again: ${JSON.stringify(err)}`);
      //       this.loaderService.display(false);
      //       this.toasterService.showToaster(
      //         'An error occurred. Please try again.',
      //         5000
      //       );
      //     },
      //     next: (data: ChangePasswordModel) => {
      //       this.loaderService.display(false);
      //       if (data.isSuccess) {
      //         // this.logger.log(`changepassword.component changePassword: ${JSON.stringify(data.isSuccess)}`);
      //         // this.toasterService.showToaster('Password has been successfully updated', 5000);
      //         this.dialogRef.close(true);
      //       } else {
      //         // this.logger.error(`changepassword.component showToaster: ${JSON.stringify(data.message)}`);
      //         this.toasterService.showToaster(data.message, 5000);
      //       }
      //     },
      //   })
    }
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password')?.value !== c.get('confirm_password')?.value) {
      return { invalid: true };
    } else {
      return { invalid: false };
    }
  }
}
