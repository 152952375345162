<h1 mat-dialog-title style="padding-right: 6px;"><span>{{ 'Orders.OrderDetail.Title' | translate }} </span>
    <button mat-icon-button color="warn" style="float: right;" (click)="onClose()"
            matTooltip="{{ 'Orders.OrderDetail.CloseButtonTootip' | translate }}">
        <mat-icon>cancel</mat-icon>
    </button>
</h1>
<mat-divider></mat-divider>
<br/>
<div mat-dialog-content>
  <mat-card style="width:500px;">
    <mat-card-content >
      <mat-list style="width:500px;">
        <h3 mat-subheader >{{ 'Orders.OrderDetail.CustomerNameLabel' | translate }}</h3>
        <div style=" display: flex;">
          <mat-icon mat-list-icon  class="dialog-icon"
          [ngStyle]="{
            'color': isCustomColor(stylesConfig.color.blue) ? stylesConfig.color.blue : null
          }"
          [color]="isCustomColor(stylesConfig.color.blue) ? null : stylesConfig.color.blue"
          >person</mat-icon>
          <h4 mat-cell style="margin-top: 3px;" >{{order.customerUserSupplier.customerMaster.name}}</h4>
        </div>
        <mat-divider></mat-divider>
        <h3 mat-subheader style="padding-top: 8px;margin-top: 3px;">{{ 'Orders.OrderDetail.DeliveryInfoLabel' | translate }}</h3>
        <div style=" display: flex;">
          <mat-icon mat-list-icon
          [ngStyle]="{
            'color': isCustomColor(stylesConfig.color.green) ? stylesConfig.color.green : null
          }"
          [color]="isCustomColor(stylesConfig.color.green) ? null : stylesConfig.color.green"
          class="dialog-icon" matTooltip="{{'Orders.OrderDetail.ShppingAddressLabel' | translate }}">
            location_on
          </mat-icon>
          <p mat-cell>{{order.shippingaddress}}</p>
        </div>

        <h3 mat-subheader style="padding-top: 8px;margin-top: 3px;" >{{ 'Orders.OrderDetail.DeliveryNotesLabel' | translate }}</h3>
        <div *ngIf="order.deliverydate" style="display: flex;">
          <mat-divider></mat-divider>
          <mat-icon mat-list-icon
          [ngStyle]="{
            'color': isCustomColor(stylesConfig.color.grey) ? stylesConfig.color.grey : null
          }"
          [color]="isCustomColor(stylesConfig.color.grey) ? null : stylesConfig.color.grey"
          class="dialog-icon" matTooltip="{{'Orders.OrderDetail.ShppingAddressLabel' | translate }}">
            date_range
          </mat-icon>
          <p mat-cell >{{order.deliverydate | date:'short'}}</p>
        </div>

        <div *ngIf="order.note" style="display: flex;">
          <mat-divider></mat-divider>
          <mat-icon mat-list-icon class="dialog-icon"
          [ngStyle]="{
            'color': isCustomColor(stylesConfig.color.dark) ? stylesConfig.color.dark : null
          }"
          [color]="isCustomColor(stylesConfig.color.dark) ? null : stylesConfig.color.dark"
          matTooltip="{{'Orders.OrderDetail.NoteLabel' | translate }}">
            note
          </mat-icon>
          <p mat-cell >{{order.note}}</p>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
    <!-- <mat-divider></mat-divider> -->
    <mat-table [dataSource]="productList" class="product-dialog-table">

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.ProductNameHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <b>{{element.productdescription}}</b>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productid">
            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.ProductIdHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <b>{{element.productid}}</b>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight">

            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.WeightHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element?.caseweight }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="quantity">

            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.QuantityHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.quantity }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">

            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.PriceAndUnitHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.unit | lowercase}}
                <!-- <button mat-mini-fab *ngIf="getCallForPrice() === 1 && (!element.price || +element.price === 0)"
                        (click)="clickedCallForPrice()"
                        matTooltip="{{ 'Orders.OrderDetail.CallForPriceButtonText' | translate }}">
                    <mat-icon>call</mat-icon>
                </button>
                <span *ngIf="getCallForPrice() === 1 && (element.price && element.price > 0)">
          {{ (element.price | currency:"USD":('Common.Common.CurrencySymbol' | translate):"1.2-2") + " / " + (element.unit | lowercase) }} </span> -->
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amount">

            <mat-header-cell *matHeaderCellDef><b>{{ 'Orders.OrderDetail.AmountHeader' | translate }}</b>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
            <span *ngIf="!element.price || +element.price === 0" style="text-align: center">
             -
            </span>

                <span *ngIf="element.price && element.price > 0">
            {{ ((element.price * element.quantity) | currency:"USD":('Common.Common.CurrencySymbol' | translate):"1.2-2")}} </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="4">
                <mat-progress-spinner class="example-margin" mode="indeterminate">
                </mat-progress-spinner>

            </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="nodata">
            <mat-footer-cell *matFooterCellDef colspan="4">
                {{ 'Orders.OrderDetail.NoDataFooterText' | translate }}
            </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="totalamount">
            <mat-footer-cell *matFooterCellDef colspan="4"
                             style="text-align:right;flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-end; align-items: center;">
                <div *ngIf="getCallForPrice() === 1 && (order.amount && order.amount > 0)">
                    <h3>{{ 'Orders.OrderDetail.TotalAmountLabel' | translate }}</h3>&nbsp;&nbsp;&nbsp;<h2>{{order.amount | currency:"USD":('Common.Common.CurrencySymbol' | translate):"1.2-2"}}</h2>
                </div>
            </mat-footer-cell>

        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumn"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumn;"></mat-row>
        <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': productList !== null}"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['nodata']"
                        [ngClass]="{'hide': !(productList !== null && productList?.data?.length === 0) }"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['totalamount']"
                        [ngClass]="{'hide': (productList!=null && productList?.data?.length === 0 && order.amount === 0) }"></mat-footer-row>
    </mat-table>
</div>
<div mat-dialog-actions>


</div>
