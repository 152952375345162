import { GlobalErrorHandler } from './core/handler/global-error-handler';
import { CommonServiceModule } from './shared/common-service.module';
import { CommonCustomModule } from './shared/common-custom.module';
import { CommonMaterialModule } from './shared/common-material.module';
import { PathNotFoundComponent } from './shared/components/path-not-found/path-not-found.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CustomLoggerService } from './logger.service';

@NgModule({
  declarations: [
    AppComponent,
    PathNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonMaterialModule,
    CommonCustomModule,
    CommonServiceModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    CustomLoggerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
