import { IAuthenticationDetailsData, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AttributeListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AppConfig } from '../config/app.config';
import { CustomLoggerService } from './../../logger.service';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

export class CognitoUtils {

  constructor(
    private logger: CustomLoggerService,
  ) {

  }
  public static getAuthDetails(email: string, password: string): IAuthenticationDetailsData {
    return {
      Username: email,
      Password: password,
    };
  }

  public static getUserPool() {
    return new CognitoUserPool({
      UserPoolId: AppConfig.settings.userPoolId,
      ClientId: AppConfig.settings.clientId //
    });
  }

  public static getCurrentUser() {
    return CognitoUtils.getUserPool().getCurrentUser();
  }

  public static getAttribute(attrs: CognitoUserAttribute[], name: string): CognitoUserAttribute {
    return attrs.find(atr => atr.getName() === name);
  }

  public static getAttributeValue(attrs: AttributeListType, name: string, defValue: any): string {
    const attr = attrs.find(atr => atr.Name === name);
    return attr ? attr.Value : defValue;
  }

  public static getActiveAttribute(attrs: AttributeListType): boolean {
    return CognitoUtils.getAttributeValue(attrs, 'custom:active', '1') === '1';
  }

  public static createNewUserAttributes(request): CognitoUserAttribute[] {
    const emailAttribute = new CognitoUserAttribute({ Name: 'email', Value: request.email });
    const activeAttribute = new CognitoUserAttribute({ Name: 'custom:active', Value: (request.active ? 1 : 0).toString() });
    return [
      emailAttribute, activeAttribute
    ];
  }

  public static createUpdatableUserAttributesData(request): AttributeListType {
    const preferedUsername = { Name: 'preferred_username', Value: request.username };
    const emailAttribute = { Name: 'email', Value: request.email };
    const emailVerifiedAttribute = { Name: 'email_verified', Value: 'true' };
    const activeAttribute = { Name: 'custom:active', Value: (request.active ? 1 : 0).toString() };
    return [
      preferedUsername, emailAttribute, emailVerifiedAttribute,
      activeAttribute
    ];
  }
  public static getIdToken(callback: Callback): void {
    if (callback == null) {
      // throw('CognitoUtil: callback in getIdToken is null...returning');
    }
    // tslint:disable-next-line:curly
    if (CognitoUtils.getCurrentUser() != null)
      CognitoUtils.getCurrentUser().getSession(function (err, session) {
        if (err) {
          // this.logger.error(`cognito-utils CognitoUtil: Can\'t set the credentials: ${JSON.stringify(err)}`);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            // this.logger.error(`cognito-utils CognitoUtil: Can\'t set the credentials: ${JSON.stringify(session)}`);
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            // this.logger.error(`cognito-utils CognitoUtil: Got the id token, but the session isn\'t valid: ${JSON.stringify(err)}`);
          }
        }
      });
    else {
      callback.callbackWithParam(null);
    }
  }

  public static getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw new Error(('CognitoUtil: callback in getRefreshToken is null...returning'));
    }
    if (CognitoUtils.getCurrentUser() != null) {
      CognitoUtils.getCurrentUser().getSession(function (err: string, session: { isValid: () => any; getRefreshToken: () => any; }) {
        if (err) {
          // this.logger.error(`cognito-utils CognitoUtil: Can\'t set the credentials: ${JSON.stringify(err)}`);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            // this.logger.error(`cognito-utils CognitoUtil: Can\'t set the credentials: ${JSON.stringify(session)}`);
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  public static refresh(): void {
    CognitoUtils.getCurrentUser().getSession(function (err: string, session: { isValid: () => any; }) {
      if (err) {
        // this.logger.error(`cognito-utils CognitoUtil: Can\'t set the credentials: ${JSON.stringify(err)}`);
      } else {
        if (session.isValid()) {
          // this.logger.error(`cognito-utils CognitoUtil: refreshed successfully: ${JSON.stringify(err)}`);
        } else {
          // this.logger.error(`cognito-utils CognitoUtil: refreshed but session is still not valid: ${JSON.stringify(err)}`);
        }
      }
    });
  }
}
