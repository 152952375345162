<!-- <button mat-icon-button color="warn"  style="float: right;" (click)="onCancel()" matTooltip="{{confirmboxData.closeButtonTooltip}}">
  <mat-icon>cancel</mat-icon>
  </button> -->
<h2 mat-dialog-title>{{confirmboxData.title}}</h2>
<mat-divider></mat-divider>
<br />

<div mat-dialog-content>
  <div class="dialog-container">

    <!-- <div fxLayout="row"> -->
<p>{{confirmboxData.message}}</p>

    <!-- </div> -->
  </div>
</div>
<br />
<mat-divider></mat-divider>

<div mat-dialog-actions style="height: 100%; flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-end; align-items: center;">
  <button mat-stroked-button color="warn" (click)="onCancel()" >
    <mat-icon>cancel</mat-icon> {{confirmboxData.NoButtonText}}
  </button> &nbsp; &nbsp;
  <button mat-stroked-button color="primary" (click)="onSuccess()" cdkFocusInitial>
      <mat-icon>check_circle</mat-icon> {{confirmboxData.YesButtonText}}
    </button>

</div>

