import { Component, OnInit, Inject } from '@angular/core';
import { CustomerProductModel } from 'src/app/core/models/customer-product.model';
import { CustomerProductDialogModel } from 'src/app/core/models/customer-product-dialog.model';
import { AppConfig } from 'src/app/core/config/app.config';
import { TranslateService } from '@ngx-translate/core';
import { CallForPriceComponent } from 'src/app/shared/components/call-for-price/call-for-price.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerProduct } from 'src/app/core/models/productCategory';
import { CustomLoggerService } from './../../logger.service';

@Component({
    selector: 'app-dialog-product-detail',
    templateUrl: './dialog-product-detail.component.html',
    styleUrls: ['./dialog-product-detail.component.scss']
})
export class DialogProductDetailComponent implements OnInit {

    addProductForm!: FormGroup;
    CustomerProductDialogObj!: CustomerProductDialogModel;
    customerProductObj!: CustomerProduct;
    ProductOprationTitle!: string;

    currentQuantity!: number;
    note!: string;

    constructor(
        public dialogRef: MatDialogRef<DialogProductDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomerProductDialogModel, private fb: FormBuilder,
        private logger: CustomLoggerService,
        private translate: TranslateService, public dialog: MatDialog) {
        this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    }

    ngOnInit() {
        // this.logger.log(`dialog-product-detail.component ngOnInit: ${JSON.stringify(this.data)}`);
        this.CustomerProductDialogObj = this.data;
        this.customerProductObj = this.CustomerProductDialogObj.customerProduct;
        if (!this.customerProductObj.quantity) {
            this.currentQuantity = 1;
        } else {
            this.currentQuantity = this.customerProductObj.quantity;
        }
        if (!this.customerProductObj.note) {
            this.note = '';
        } else {
            this.note = this.customerProductObj.note;
        }
        this.addProductForm = this.fb.group({
            quantity: [this.currentQuantity, [Validators.required, Validators.min(1)]],
            note: [this.note],
        });

        this.ProductOprationTitle = this.customerProductObj.description;
    }

    onClose(): void {
        this.dialogRef.close(null);
    }

    onSubmitGoToCart(): void {
        this.onSubmit(true);

    }

    onSubmitMoreItems(): void {
        this.onSubmit(false);

    }

    onSubmit(redirectToCart: boolean): void {
        // this.logger.log(`dialog-product-detail.component redirectToCart: ${JSON.stringify(redirectToCart)}`);
        if (this.addProductForm.valid) {
            this.CustomerProductDialogObj.customerProduct.quantity = this.addProductForm.get('quantity')?.value;
            this.CustomerProductDialogObj.customerProduct.note = this.addProductForm.get('note')?.value;
            this.CustomerProductDialogObj.redirectToCart = redirectToCart;
            this.dialogRef.close(this.CustomerProductDialogObj);
        }
    }

    clickedCallForPrice() {
        // this.logger.log(`dialog-product-detail.component clickedCallForPrice: ${JSON.stringify(this.CustomerProductDialogObj.customerId)}`);
        const dialogRef = this.dialog.open(CallForPriceComponent, {
            width: '470px',
            data: this.CustomerProductDialogObj.customerId
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.logger.log(`dialog-product-detail.component afterClosed: ${JSON.stringify(result)}`);
            if (result && result != null) {
            }

        });
    }

    getCallForPrice(): number {
        return AppConfig.settings.CallForPriceWhenZero;
    }
}

