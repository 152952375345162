// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  aws: {
    accessKeyId: 'AKIAVHFSHIBN2FKUWUEO',
    secretAccessKey: '2WlMwsqQ3CIzrQdO708fxEb4W031SnvnGzt9ZyXa',
    region: 'us-east-1',
  },
  nodeEnv: 'production',
  cloudWatch: {
    groupName: 'unifiedui',
    streamName: 'unifiedui',
  },
  logAwsS3: {
    accessKeyId: 'AKIA5FTZFFKFCUYIXH7K',
    secretAccessKey: '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',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
