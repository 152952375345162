import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/core/config/app.config';
import { CustomerModel } from 'src/app/core/models/customer.model';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrdersService } from 'src/app/core/services/orders.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { HttpErrorModel } from 'src/app/core/models/http.error.model';
import { CustomerContactDetailModel } from 'src/app/core/models/customer-contact-detail.model';
import { MatSelectChange } from '@angular/material/select';
import { CustomerUserSupplier } from 'src/app/core/models/response';
import { CustomLoggerService } from './../../../logger.service';

@Component({
  selector: 'app-call-for-price',
  templateUrl: './call-for-price.component.html',
  styleUrls: ['./call-for-price.component.scss'],
})
export class CallForPriceComponent implements OnInit {
  customerId: string;
  isCustomerControlDisabled!: boolean;
  customers: CustomerUserSupplier[] = [];
  customerSelected!: boolean;
  customerOrderForm!: FormGroup;
  contactDetail!: CustomerContactDetailModel;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CallForPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private ordersService: OrdersService,
    private toasterService: ToasterService,
    private logger: CustomLoggerService,
  ) {
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    this.customerId = data;
  }

  ngOnInit() {
    if (this.customerId) {
      // this.logger.log(`call-for-price.component fb.group: ${JSON.stringify(this.customerId)}`);
      this.customerOrderForm = this.fb.group({
        customerSelect: [this.customerId],
      });
      this.loadCustomerData();
      this.isCustomerControlDisabled = true;
    } else {
      this.customerOrderForm = this.fb.group({
        customerSelect: ['0'],
      });
      this.loadCustomerData();
      this.isCustomerControlDisabled = false;
      // this.logger.log(`call-for-price.component isCustomerControlDisabled: ${JSON.stringify(this.customerId)}`);
    }
  }

  onSuccess() {
    // this.logger.log("call-for-price.component onSuccess:");
    this.dialogRef.close(true);
  }

  onCancel() {
    // this.logger.log("call-for-price.component onCancel:");
    this.dialogRef.close(false);
  }
  onChangeCustomerSelection(event: MatSelectChange) {
    // this.logger.log(`call-for-price.component onChangeCustomerSelection: ${JSON.stringify(event.value)}`);
    this.onChangeCustomerSelectionValue(event.value);
  }
  loadCustomerContactDetailData(customerSelectedValue: string) {
    // this.logger.log(`call-for-price.component loadCustomerContactDetailData: ${JSON.stringify(customerSelectedValue)}`);
    this.ordersService
      .getCustomercontactdetail(customerSelectedValue)
      .subscribe({
        complete: () => { },
        error: (err: HttpErrorModel) => {
          this.loaderService.display(false);
          this.toasterService.showToaster(err.message, 5000);
          // this.logger.error(`call-for-price.component getCustomercontactdetail: ${JSON.stringify(err.message)}`);
        },
        next: (contactDetail: CustomerContactDetailModel) => {
          // this.logger.log(`call-for-price.component contactDetail: ${JSON.stringify(contactDetail)}`);
          this.contactDetail = contactDetail;
          this.loaderService.display(false);
        },
      })
  }
  loadCustomerData() {
    // this.logger.log("call-for-price.component loadCustomerData:");
    this.ordersService.getcustomers()
      .subscribe({
        complete: () => { },
        error: (err: HttpErrorModel) => {
          this.loaderService.display(false);
          this.toasterService.showToaster(err.message, 5000);
          // this.logger.error(`call-for-price.component loadCustomerData: ${JSON.stringify(err.message)}`);
        },
        next: (customersData: CustomerUserSupplier[]) => {
          if (customersData) {
            this.customers = customersData;
            this.onChangeCustomerSelectionValue(this.customerId);
            this.loaderService.display(false);
            // this.logger.log(`call-for-price.component CustomerUserSupplier: ${JSON.stringify(this.customerId)}`);
          }
        },
      })
  }
  onChangeCustomerSelectionValue(customerSelectedValue: string) {
    // this.logger.log(`call-for-price.component onChangeCustomerSelectionValue: ${JSON.stringify(customerSelectedValue)}`);
    this.contactDetail = null;
    this.loaderService.display(true);
    if (customerSelectedValue && +customerSelectedValue > 0) {
      this.customerSelected = true;
      this.loadCustomerContactDetailData(customerSelectedValue);
    } else {
      this.customerSelected = false;
      this.loaderService.display(false);
    }
  }
}
