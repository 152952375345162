import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomLoggerService } from './../../logger.service';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.scss']
})
export class UpdateuserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateuserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private logger: CustomLoggerService,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    // this.logger.log("updateuser.component onNoClick:")
    this.dialogRef.close();
  }

  onClickOK() {
    // this.logger.log("updateuser.component onClickOK:")
    this.dialogRef.close(this.data);
  }

}
