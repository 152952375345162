import { Injectable, ErrorHandler } from '@angular/core';
import { AppErrorModel } from '../models/AppError.model';
import { CustomLoggerService } from './../../logger.service';

@Injectable()
export class AppErrorHandlerService implements ErrorHandler {

  handleError(error: any): void {
    const customError: AppErrorModel = new AppErrorModel();
    customError.errorNumber = 200;
    customError.message = (<Error>error).message;
    customError.friendlyMessage = 'An error occurred. Please try again.';

    // this.logger.log(`app-error-handler.service handleError: ${JSON.stringify(customError)}`);
  }

  constructor(
    private logger: CustomLoggerService,
  ) { }

}
