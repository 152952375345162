import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../authentication/user.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private userService: UserService,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // console.log('In canActivate: ' + state.url);
        return this.isUserLoggedIn(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // console.log('In canActivateChild: ' + state.url);
        return this.isUserLoggedIn(state.url);
    }

    canLoad(route: Route): boolean {
        // console.log('In canLoad: ' + route.path);
        return this.isUserLoggedIn(route.path);
    }

    isUserLoggedIn(url: string): boolean {
        if (this.userService.isUserLoggedIn()) {
            return true;
        } else {
            this.userService.redirectUrl = url;
            this.router.navigate(['/login']);
            return false;
        }
    }
}
