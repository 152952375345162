import { Component, OnInit, Inject } from '@angular/core';
import { DialogProductDetailComponent } from 'src/app/orders/dialog-product-detail/dialog-product-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/core/config/app.config';
import { ConfirmboxModel } from 'src/app/core/models/confirmbox.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmbox',
  templateUrl: './confirmbox.component.html',
  styleUrls: ['./confirmbox.component.scss']
})
export class ConfirmboxComponent implements OnInit {

  confirmboxData: ConfirmboxModel;
  constructor(
    public dialogRef: MatDialogRef<DialogProductDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmboxModel,
    private translate: TranslateService) {
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    this.confirmboxData = data;
  }

  ngOnInit() {
  }

  onSuccess() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
