import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../core/authentication/user.service';
import { AppConfig } from 'src/app/core/config/app.config';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { AlertboxModel } from 'src/app/core/models/confirmbox.model';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { CallForPriceComponent } from '../call-for-price/call-for-price.component';

@Component({
  selector: 'app-header',
  templateUrl: '../header/header.component.html',
})
export class HeaderComponent implements OnInit {
  configLogoURL = AppConfig.settings.logoImage;
  stylesConfig:any={};
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    this.stylesConfig = localStorage.getItem('styles')  ? JSON.parse(localStorage.getItem('styles')) :{}

  }

  logout(): void {
    this.userService.logOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  ngOnInit() {}

  // onClickUserManagement() {
  //   this.router.navigate(['/users']);
  // }

  onClickChangePassword() {
    if (!this.userService.isUserLoggedIn()) {
      this.router.navigate(['/login']);
      return;
    }
    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      width: '400px',
      data: null,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result != null) {
        const alertboxModel: AlertboxModel = new AlertboxModel();
        alertboxModel.message = this.translate.instant(
          'Common.ChangePassword.SuccessMessage'
        );
        alertboxModel.YesButtonText = this.translate.instant(
          'Common.ChangePassword.SuccessButtonText'
        );
        alertboxModel.title = this.translate.instant(
          'Common.ChangePassword.SuccessDialogTitle'
        );
        const dialogRef2 = this.dialog.open(AlertboxComponent, {
          width: '700px',
          data: alertboxModel,
        });
        dialogRef2.afterClosed().subscribe((result2) => {
          this.router.navigate(['/login']);
        });
      }
    });
  }

  clickedCallForPrice() {
    const dialogRef = this.dialog.open(CallForPriceComponent, {
      width: '470px',
      data: null,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result != null) {
      }
    });
  }

  gotoCheckout() {
    if (this.getCartCount() > 0) {
      localStorage.setItem('CartPage', 'checkout');
      this.router.navigate(['/orders/checkout']);
    }
  }

  getCartCount() {
    return localStorage.getItem('CartObjectCount') || 0;
  }
}
