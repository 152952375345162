import { Component, OnInit } from '@angular/core';
import { NavigationStart, Event, NavigationEnd, NavigationCancel, NavigationError, Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({

  templateUrl: './login-layout.component.html'
})
export class LoginLayoutComponent implements OnInit {

  constructor(private loaderService: LoaderService, private router: Router ) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
   }

  ngOnInit() { }
  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loaderService.display(true);
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loaderService.display(false);
    }
  }
}

