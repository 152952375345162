export interface Delivery {
    ordercutofftime: number;
    deliveryDate: Date;
    message: string;
}

export class Customeraddress {
    address: string;
    deliverydayofweeks: string;
    addressid: number;
    isdeleted: boolean;
    _id: string;
    delivery: Delivery;
}

export class CustomerMaster {
    _id: string;
    supplier: string;
    name: string;
    customerId?: any;
    address: string;
    phoneno: string;
    isactive: boolean;
    maxorderprice: string;
    repname: string;
    repemail: string;
    repphone: string;
    repmobilephone: string;
    customeraddress: Customeraddress[];
    status: string;
    createTime: Date;
    updateTime: Date;
}

export class User {
    _id: string;
    awsuserid: string;
    emailaddress: string;
    firstname: string;
    lastname: string;
    address: string;
    mobilenumber: string;
    status: string;
    createTime: Date;
    updateTime: Date;
}

export class Supplier {
    _id: string;
    supplierkey: string;
    supplierdomain: string;
    timezoneoffset: string;
    cutofftime: string;
    name: string;
    phone?: any;
    logoImage: string;
    apiUrl: string;
    defaultLanguage: string;
    orderStatus: number;
    orderDuration: number;
    userPoolId: string;
    clientId: string;
    domain: string;
    CallForPriceWhenZero: number;
    status: string;
    createTime: Date;
    updateTime: Date;
}

export class CustomerUserSupplier {
    _id: string;
    customerMaster: CustomerMaster;
    user: User;
    supplier: Supplier;
    isactive: boolean;
    lastupdateddate: Date;
    lastupdatedBy?: any;
    status: string;
    createTime: Date;
    updateTime: Date;
}

export class Orderline {
    productid: string;
    productidstr: string;
    customerproductid: string;
    unit: string;
    lastupdatedby: string;
    quantity: number;
    price: number;
    productdescription: string;
    comment: string;
    _id: string;

    eachitem: number;
    eachcount: number;
    caseweight: number;
}

export class Order {
    _id: string;
    customerUserSupplier: CustomerUserSupplier;
    customeraddress?: any;
    note: string;
    shippingaddress?: any;
    deliverydate: Date;
    ppsorderid: string;
    orderstatus: number;
    orderuser: string;
    lastupdatedby: string;
    orderline: Orderline[];
    CustomerPO: string;
    status: string;
    createTime: Date;
    updateTime: Date;

    itemsCount: number;
    itemsAmount: number;
    noOfItems: number;
    amount: number;
    totalLbs: number;
    Committed: boolean;
}

export interface AllCustomer {
    _id: string;
    customerMaster: CustomerMaster;
    user: User;
    supplier: Supplier;
    isactive: boolean;
    lastupdateddate: Date;
    lastupdatedBy?: any;
    status: string;
    createTime: Date;
    updateTime: Date;
}
