import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CognitoUserSession, CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
// import { ListUsersRequest } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { CognitoUtils } from './cognito-utils';
import { AuthModel } from '../models/auth.model';
import { BehaviorSubject } from 'rxjs';
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { ChangePasswordModel } from '../models/change-password.model';
import { CustomLoggerService } from './../../logger.service';


@Injectable()
export class UserService {
  session!: CognitoUserSession;
  redirectUrl!: string;
  // cognitoAdminService: AWS.CognitoIdentityServiceProvider;
  userPool: CognitoUserPool;
  authModel: AuthModel = new AuthModel();
  forgotPasswordModel!: ForgotPasswordModel;

  constructor(
    private logger: CustomLoggerService,
  ) {
    // this.cognitoAdminService = new AWS.CognitoIdentityServiceProvider({
    //     accessKeyId: environment.awsConfig.accessKeyId,
    //     secretAccessKey: environment.awsConfig.secretAccessKey,
    //     region: environment.awsConfig.region
    // });
    this.userPool = CognitoUtils.getUserPool();
  }

  public login(login: string, password: string): Observable<AuthModel> {
    const cognitoUser = new CognitoUser(this.getUserData(login));
    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const authenticationDetails = new AuthenticationDetails(CognitoUtils.getAuthDetails(login, password));
    return new Observable(obs => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          this.session = result;
          // const token = result.getIdToken();
          // const accessToken = result.getAccessToken();
          this.authModel.isSuccess = result.isValid();
          if (this.authModel.isSuccess) {
            this.authModel.result = result;
            this.authModel.username = login;
            this.authModel.message = 'success';
          } else {
            this.authModel.isSuccess = false;
            this.authModel.result = result;
            this.authModel.username = login;
            this.authModel.message = 'An error occurred. Please try again.';
          }
          return obs.next(this.authModel);
        },
        onFailure: (err: { code: any; message: string; }) => {
          console.error(err);
          this.authModel.isSuccess = false;
          this.authModel.result = err;
          this.authModel.username = login;
          switch (err.code) {
            case 'UserNotFoundException':
              // Only here, in .catch error block we actually send a user to sign up
              this.authModel.message = err.message;
              return obs.next(this.authModel);
            /* falls through */
            case 'NotAuthorizedException':
              this.authModel.message = err.message;
              return obs.next(this.authModel);
            /* falls through */
            case 'PasswordResetRequiredException':
              this.authModel.message = err.message;
              return obs.next(this.authModel);
            /* falls through */
            case 'UserNotConfirmedException':
              this.authModel.message = err.message;
              return obs.next(this.authModel);
            /* falls through */
            default:
              this.authModel.message = err.message;
              return obs.next(this.authModel);
            /* falls through */

          }
        },
        newPasswordRequired: () => {
          this.authModel.isSuccess = false;
          this.authModel.username = login;
          this.authModel.message = 'New Password Required..!!';
          return obs.next(this.authModel);
        }
      });
    });
  }

  private getUserData(email: string) {
    return {
      Username: email,
      Pool: this.userPool
    };
  }
  public forgotPassword(username: string): Observable<ForgotPasswordModel> {

    const cognitoUser = new CognitoUser(this.getUserData(username));
    return new Observable(obs => {
      cognitoUser.forgotPassword({
        onSuccess: function () {
          this.forgotPasswordModel = new ForgotPasswordModel();
          this.forgotPasswordModel.isSuccess = true;
          this.forgotPasswordModel.username = username;
          this.forgotPasswordModel.message = 'SUCCESS';
          return obs.next(this.forgotPasswordModel);
        },
        onFailure: function (err: { message: any; }) {
          this.forgotPasswordModel = new ForgotPasswordModel();
          this.forgotPasswordModel.isSuccess = false;
          this.forgotPasswordModel.username = username;
          this.forgotPasswordModel.message = err.message;
          return obs.next(this.forgotPasswordModel);
        },
        inputVerificationCode() {
          this.forgotPasswordModel = new ForgotPasswordModel();
          this.forgotPasswordModel.isSuccess = true;
          this.forgotPasswordModel.isRequiredSecondStep = true;
          this.forgotPasswordModel.username = username;
          return obs.next(this.forgotPasswordModel);
        }
      });
    });
  }

  public confirmNewPassword(forgotPwdModel: ForgotPasswordModel): Observable<ForgotPasswordModel> {
    const cognitoUser = new CognitoUser(this.getUserData(forgotPwdModel.username));
    return new Observable(obs => {
      cognitoUser.confirmPassword(forgotPwdModel.verificationCode, forgotPwdModel.password, {
        onSuccess: function () {
          forgotPwdModel.isSuccess = true;
          return obs.next(forgotPwdModel);
        },
        onFailure: function (err: { message: string; }) {
          forgotPwdModel.isSuccess = false;
          forgotPwdModel.message = err.message;
          return obs.next(forgotPwdModel);
        }
      });
    });
  }

  public changePassword(changePasswordModel: ChangePasswordModel): Observable<ChangePasswordModel> {
    changePasswordModel.isLogOut = false;
    const cognitoUser = this.userPool.getCurrentUser();
    let sessionValid = false;
    cognitoUser?.getSession(function (err: any, session: { isValid: any; }) {
      if (err) {
        // this.logger.error(`user.service An error occurred. Please try again: ${JSON.stringify(err)}`);
        changePasswordModel.isSuccess = false;
        changePasswordModel.message = 'An error occurred. Please try again.';
      } else if (session && session.isValid) {
        sessionValid = true;
      } else {
        changePasswordModel.isLogOut = true;
        changePasswordModel.isSuccess = false;
        changePasswordModel.message = 'An error occurred. Please try again.';
      }


    });
    if (sessionValid) {
      return new Observable(obs => {

        cognitoUser?.changePassword(changePasswordModel.existingPassword, changePasswordModel.password, function (err: { message: string; }, result: any) {
          if (err) {
            changePasswordModel.isSuccess = false;
            // this.logger.error(`user.service An error occurred. Please try again: ${JSON.stringify(err)}`);
            if (err.message) {
              changePasswordModel.message = err.message;
            } else {
              changePasswordModel.message = 'An error occurred. Please try again.';
            }
            return obs.next(changePasswordModel);
          }
          // this.logger.error(`user.service result: ${JSON.stringify(result)}`);
          changePasswordModel.isSuccess = true;
          return obs.next(changePasswordModel);
        });
      });
    } else {
      return new Observable(obs => {
        return obs.next(changePasswordModel);
      });
    }

  }

  isUserLoggedIn(): boolean {
    if (localStorage.getItem('supplierkey')) {
      return true;
    }
    return false;
  }

  // isUserLoggedIn(): boolean {
  //   if (this.userPool.getCurrentUser() != null && localStorage.getItem('supplierkey')) {
  //     const cognitoUser = this.userPool.getCurrentUser();
  //     let sessionValid: boolean;
  //     sessionValid = false;
  //     cognitoUser?.getSession(function (err: any, session: { isValid: any; }) {
  //       if (err) {
  //         console.log(err);
  //         sessionValid = false;
  //       } else if (session && session.isValid) {
  //         sessionValid = true;
  //       } else {
  //         sessionValid = false;
  //       }
  //     });
  //     return sessionValid;
  //   }
  //   return false;
  // }
  isUserLoggedInObservable(): BehaviorSubject<boolean> {
    return new BehaviorSubject<boolean>(this.isUserLoggedIn());
  }

  getAuthenticatedUser() {
    return this.userPool.getCurrentUser();
  }

  logOut() {
    this.getAuthenticatedUser()?.signOut();
  }
}
