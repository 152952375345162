import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/core/config/app.config';
import { AlertboxModel } from 'src/app/core/models/confirmbox.model';
import { CustomLoggerService } from './../../../logger.service';

@Component({
  selector: 'app-alertbox',
  templateUrl: './alertbox.component.html',
  styleUrls: ['./alertbox.component.scss']
})
export class AlertboxComponent implements OnInit {

  alertboxData: AlertboxModel;
  constructor(
    public dialogRef: MatDialogRef<AlertboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertboxModel,
    private logger: CustomLoggerService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    this.alertboxData = data;
  }

  ngOnInit() {
  }

  onSuccess() {
    // this.logger.log("alertbox.component onSuccess");
    this.dialogRef.close(true);
  }

  onCancel() {
    // this.logger.log("alertbox.component onCancel");
    this.dialogRef.close(false);
  }
}
