
export class ConfirmboxModel {
  title: string;
  message: string;
  YesButtonText: string;
  NoButtonText: string;
}
export class AlertboxModel {
  title: string;
  message: string;
  YesButtonText: string;
}
