import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  template: '<div class="loader-overlay" *ngIf="showLoader"><mat-progress-bar mode="indeterminate" color="warn" ></mat-progress-bar></div>',
  // providers: [LoaderService],
  styles: ['.loader-hidden {visibility: hidden;} .loader-overlay {position: absolute;width:100%;top:0;left: 0;opacity: 1;z-index: 500000;}']
})

export class LoaderComponent implements OnInit {
  showLoader: boolean = false;
  constructor(private loaderService: LoaderService) {

  }

  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.showLoader = val;
      }, 10);
    });
  }
}
