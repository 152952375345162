import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomLoggerService {
  private cloudWatchLogs: AWS.CloudWatchLogs;
  private logGroupName: string;
  private logStreamName: string;

  constructor() {
    const accessKeyId = environment.logAwsS3.accessKeyId;
    const secretAccessKey = environment.logAwsS3.secretAccessKey;
    const region = environment.aws.region;
    this.logGroupName = environment.cloudWatch.groupName;
    this.logStreamName = environment.cloudWatch.streamName;

    this.cloudWatchLogs = new AWS.CloudWatchLogs({
      accessKeyId,
      secretAccessKey,
      region,
    });

    this.createLogStream();
  }

  private async createLogStream() {
    try {
      await this.cloudWatchLogs.createLogStream({
        logGroupName: this.logGroupName,
        logStreamName: this.logStreamName,
      }).promise();
    } catch (error) {
      if (error.code !== 'ResourceAlreadyExistsException') {
        console.error('Error creating log stream:', error);
      }
    }
  }

  private async putLogEvents(message: string, level: string) {
    try {
      const logEvents = [{
        message: `${level}: ${message}`,
        timestamp: new Date().getTime(),
      }];
      const params = {
        logEvents,
        logGroupName: this.logGroupName,
        logStreamName: this.logStreamName,
      };
      await this.cloudWatchLogs.putLogEvents(params).promise();
    } catch (error) {
      console.error('Error putting log events:', error);
    }
  }

  log(message: any) {
    this.putLogEvents(message, 'INFO');
  }

  error(message: string) {
    this.putLogEvents(message, 'ERROR');
  }

  warn(message: string) {
    this.putLogEvents(message, 'WARN');
  }

  debug(message: string) {
    this.putLogEvents(message, 'DEBUG');
  }

  verbose(message: string) {
    this.putLogEvents(message, 'VERBOSE');
  }
}
