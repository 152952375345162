import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppConfig } from '../models/app-config.model';
import { HttpService } from '../http/http.service';

@Injectable()
export class AppConfig {
  static settings: IAppConfig;
  apiUrl = 'http://3.209.111.170:4001/supplier';
  //apiUrl = 'http://localhost:4001/supplier';
  jsonFile = `assets/config/config.json`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
      supplierkey: 'a9ec67c4-744f-4e8a-8764-7de69cd158d7',
    }),
  };

  constructor(private http: HttpService) {}

  load() {
    return new Promise<void>((resolve, reject) => {
      let searchFilter =
        '/' + window.location.hostname.split('.')[0].toString();

       // searchFilter = '/kjpoultry'

      this.http
        .get<IAppConfig[]>(this.apiUrl + searchFilter, this.httpOptions)
        .subscribe(
          (response: IAppConfig[]) => {
            if (response.length > 0) {
              AppConfig.settings = response[0];
              resolve();
            } else {
              this.getLocal(resolve, reject);
            }
          },
          (error) => {
            reject(
              `Could not load file '${this.jsonFile}': ${JSON.stringify(error)}`
            );
          }
        );
    });
  }

  getLocal(resolve: any, reject: any) {
    this.http.get<IAppConfig>(this.jsonFile).subscribe(
      (response) => {
        if (response[window.location.hostname.split('.')[0]?.toString()]) {
          AppConfig.settings = response[
            window.location.hostname.split('.')[0]?.toString()
          ] as IAppConfig;
        } else {
          AppConfig.settings = <IAppConfig>response.default;
        }

        resolve();
      },
      (error) => {
        reject(
          `Could not load file '${this.jsonFile}': ${JSON.stringify(error)}`
        );
      }
    );
  }
}
