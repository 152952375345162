import { CustomerRuleModel } from './customer-rule.model';
import { CustomerProduct } from './productCategory';

export class CustomerProductDialogModel {
  customerRule: CustomerRuleModel;
  customerProduct: CustomerProduct;
  redirectToCart: boolean;
  fromCheckoutScreen: boolean;
  customerId: string;
}
