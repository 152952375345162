<mat-toolbar [color]="stylesConfig.color.accent">
  <mat-toolbar-row>
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <a [routerLink]="['/orders']"><img [src]="configLogoURL" height="40" /></a>

    <button
      mat-button
      [routerLink]="['/orders']"
      routerLinkActive="active"
      fxShow="true"
      fxHide.lt-md="true"
    >
      <mat-icon>dashboard</mat-icon
      ><span> {{ "Common.Header.DashboardMenuText" | translate }}</span>
    </button>


    <!-- <button
      mat-button
      (click)="clickedCallForPrice()"
      fxShow="true"
      fxHide.lt-md="true"
    >
      <mat-icon>call</mat-icon
      ><span>{{ "Common.Header.ContactMenuText" | translate }}</span>
    </button> -->
    <!-- <button mat-button [routerLink]="['/otherpage']" routerLinkActive="active">
      <mat-icon matBadge="localStorage.getItem('CartObjectCount')" matBadgeColor="warn">shopping_cart</mat-icon>  <span id="cartCount"></span>
    </button> -->
    <span class="example-fill-remaining-space"></span>
    <span class="align-center"></span>
    <span class="example-spacer"></span>
    <button
      mat-button
      routerLinkActive="active"
      fxShow="true"
      fxHide.lt-md="true"
      (click)="gotoCheckout()"
    >
      <mat-icon [matBadge]="getCartCount() > 0 ? getCartCount() : ''" matBadgeColor="warn">shopping_cart_checkout</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      fxShow="true"
      fxHide.lt-md="true"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" fxShow="true" fxHide.lt-md="true">
      <!-- <button
        mat-menu-item
        (click)="onClickUserManagement()"
        fxShow="true"
        fxHide.lt-md="true"
      >
        <mat-icon>manage_accounts</mat-icon
        ><span> {{ "Common.Header.UsersMenuText" | translate }}</span>
      </button> -->

      <button
        mat-menu-item
        (click)="onClickChangePassword()"
        fxShow="true"
        fxHide.lt-md="true"
      >
        <mat-icon>settings</mat-icon
        ><span> {{ "Common.Header.ChangePasswordText" | translate }}</span>
      </button>
      <button
        mat-menu-item
        (click)="logout()"
        fxShow="true"
        fxHide.lt-md="true"
      >
        <mat-icon>power_settings_new</mat-icon
        ><span> {{ "Common.Header.LogoutText" | translate }}</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container fxFlexFill class="example-container">
  <mat-sidenav
    color="primary"
    #sidenav
    fxLayout="column"
    mode="over"
    opened="false"
    fxHide.gt-sm="true"
  >
    <div fxLayout="column">
      <button
        mat-menu-item
        [routerLink]="['/orders']"
        routerLinkActive="active"
        (click)="sidenav.toggle()"
      >
        <mat-icon>dashboard</mat-icon
        ><span> {{ "Common.Header.DashboardMenuText" | translate }}</span>
      </button>
      <button mat-menu-item (click)="clickedCallForPrice(); sidenav.toggle()">
        <mat-icon>call</mat-icon
        ><span>{{ "Common.Header.ContactMenuText" | translate }}</span>
      </button>
      <button mat-menu-item (click)="onClickChangePassword(); sidenav.toggle()">
        <mat-icon>settings</mat-icon
        ><span> {{ "Common.Header.ChangePasswordText" | translate }}</span>
      </button>
      <button mat-menu-item (click)="logout(); sidenav.toggle()">
        <mat-icon>power_settings_new</mat-icon
        ><span> {{ "Common.Header.LogoutText" | translate }}</span>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
