import { DeleteuserComponent } from './../users-management/deleteuser/deleteuser.component';
import { UpdateuserComponent } from './../users-management/updateuser/updateuser.component';
import { DialogDeliverydateComponent } from './../orders/dialog-deliverydate/dialog-deliverydate.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CommonMaterialModule } from './common-material.module';
import { LoginLayoutComponent } from './components/layouts/login-layout.component';
import { MainLayoutComponent } from './components/layouts/main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { DialogProductDetailComponent } from '../orders/dialog-product-detail/dialog-product-detail.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DialogOrderDetailComponent } from '../orders/dialog-order-detail/dialog-order-detail.component';
import { ConfirmboxComponent } from './components/confirmbox/confirmbox.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { AlertboxComponent } from './components/alertbox/alertbox.component';
import { CallForPriceComponent } from './components/call-for-price/call-for-price.component';
import { DialogOrderSuccessComponent } from '../orders/dialog-order-success/dialog-order-success.component';
import { ExportAsModule } from 'ngx-export-as';
import { EmailFormComponent } from '../orders/email-form/email-form.component';
import { TruncatePipe } from '../core/directives/truncate.pipe';
import { CustomLoggerService } from './../logger.service';

@NgModule({
  declarations: [
    LoginLayoutComponent,
    MainLayoutComponent,
    HeaderComponent,
    DialogProductDetailComponent,
    DialogOrderDetailComponent,
    LoaderComponent,
    ConfirmboxComponent,
    ChangepasswordComponent,
    AlertboxComponent,
    CallForPriceComponent,
    DialogOrderSuccessComponent,
    InfiniteScrollComponent,
    DialogDeliverydateComponent,
    UpdateuserComponent,
    DeleteuserComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonMaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    HttpClientModule,
    ExportAsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonMaterialModule,
    HttpClientModule,
    TranslateModule,
    DialogProductDetailComponent,
    DialogOrderDetailComponent,
    ConfirmboxComponent,
    LoaderComponent,
    HttpClientModule,
    ChangepasswordComponent,
    AlertboxComponent,
    CallForPriceComponent,
    DialogOrderSuccessComponent,
    InfiniteScrollComponent,
    DialogDeliverydateComponent,
    UpdateuserComponent,
    DeleteuserComponent,
  ],
  providers: [CustomLoggerService],
  // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  entryComponents: [
    DialogProductDetailComponent,
    DialogOrderDetailComponent,
    ConfirmboxComponent,
    ChangepasswordComponent,
    AlertboxComponent,
    CallForPriceComponent,
    DialogOrderSuccessComponent,
    DialogDeliverydateComponent,
    UpdateuserComponent,
    DeleteuserComponent,
    EmailFormComponent
  ]
})
export class CommonCustomModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

