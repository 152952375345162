import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { CustomLoggerService } from './../../logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server error
            // this.logger.error(`global-error-handler Server error: ${JSON.stringify(error)}`);
        } else {
            // Client Error
            // this.logger.error(`global-error-handler Client Error: ${JSON.stringify(error)}`);
        }
    }
    constructor(
        private logger: CustomLoggerService,
    ) { }
}