<button
  mat-icon-button
  color="warn"
  style="float: right; z-index: 100;"
  (click)="onClose()"
  matTooltip="{{ 'Orders.ProductDetail.CloseButtonTootip' | translate }}"
>
  <mat-icon>cancel</mat-icon>
</button>
<h1 mat-dialog-title>{{ ProductOprationTitle }}</h1>
<mat-divider></mat-divider>
<br />
<form class="example-form" [formGroup]="addProductForm">
  <div mat-dialog-content>
    <div class="dialog-container">
      <!-- <div fxLayout="row"> -->
      <div class="example-container">
        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <div class="example-container">
              <ng-container *ngIf="customerProductObj.eachitem === false">
                <mat-label>{{
                  "Orders.ProductDetail.ExtendedWeightUnit" | translate
                }}</mat-label>
                <mat-label style="margin-top: 8px">
                  {{
                    customerProductObj.caseweight *
                      addProductForm?.get("quantity")?.value
                  }}
                  lbs.
                </mat-label>
              </ng-container>
              <ng-container *ngIf="customerProductObj.eachitem === true">
                <mat-label>{{
                  "Orders.ProductDetail.ExtendedWeightEach" | translate
                }}</mat-label>
                <mat-label style="margin-top: 8px">
                  {{
                    customerProductObj.eachcount *
                      addProductForm?.get("quantity")?.value
                  }}
                  count(s).
                </mat-label>
              </ng-container>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="dialog-form-full-width">
              <input
                class="right"
                matInput
                type="number"
                placeholder="{{
                  'Orders.ProductDetail.QuantityPlaceholder' | translate
                }}"
                name="quantity"
                formControlName="quantity"
                required
                cdkFocusInitial
                [ngClass]="{
                  'is-invalid':
                    (addProductForm.get('quantity')?.touched ||
                      addProductForm.get('quantity')?.dirty) &&
                    !addProductForm.get('quantity')?.valid
                }"
              />
              <!--<span matSuffix>{{customerProductObj.unit | lowercase}}</span>-->
              <mat-error
                *ngIf="addProductForm.get('quantity')?.errors?.required"
              >
                {{
                  "Orders.ProductDetail.QuantityRequireValidationMessage"
                    | translate
                }}
              </mat-error>
              <mat-error *ngIf="addProductForm.get('quantity')?.errors?.min">
                {{
                  "Orders.ProductDetail.QuantityInvalidateValidationMessage"
                    | translate
                }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <br />
      <button
        mat-raised-button
        *ngIf="
          getCallForPrice() === 1 &&
          !customerProductObj.price && customerProductObj.price === 0
        "
        (click)="clickedCallForPrice()"
      >
        <mat-icon>call</mat-icon>
        {{ "Orders.ProductDetail.CallForPriceButtonText" | translate }}
      </button>
      <mat-form-field
        class="half"
        *ngIf="
          getCallForPrice() === 1 &&
          +customerProductObj.price > 0 &&
          addProductForm.get('quantity')?.value > 0
        "
      >
        <input
          class="right"
          matInput
          placeholder="{{
            'Orders.ProductDetail.ExtendedWeightHeader' | translate
          }}"
          value="{{
            customerProductObj.caseweight *
              addProductForm.get('quantity')?.value +
              ('Orders.ProductDetail.ExtendedWeightUnit' | translate)
          }}"
          disabled="disabled"
        />
      </mat-form-field>

      <mat-form-field
        class="half"
        *ngIf="
          getCallForPrice() == 1 &&
          +customerProductObj.price > 0 &&
          addProductForm.get('quantity')?.value > 0
        "
      >
        <input
          class="right"
          matInput
          placeholder="{{
            'Orders.ProductDetail.PricePlaceholder' | translate
          }}"
          value="{{
            customerProductObj.price * addProductForm.get('quantity')?.value
              | currency
                : 'USD'
                : ('Common.Common.CurrencySymbol' | translate)
                : '1.2-2'
          }}"
          disabled="disabled"
        />
      </mat-form-field>

      <mat-form-field class="dialog-form-full-width">
        <input
          matInput
          type="text"
          placeholder="{{ 'Orders.ProductDetail.NotePlaceholder' | translate }}"
          name="note"
          formControlName="note"
          cdkFocusInitial
        />
      </mat-form-field>
      <!-- </div> -->
    </div>
  </div>
  <div
    mat-dialog-actions
    style="
      height: 100%;
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center flex-end;
      align-items: center;
    "
  >
    <!-- <button mat-stroked-button color="warn" (click)="onClose()">
          <mat-icon>cancel</mat-icon> Cancel
        </button>
        <span>&nbsp;</span> -->
    <button
      mat-stroked-button
      color="accent"
      (click)="onSubmitMoreItems()"
      *ngIf="!CustomerProductDialogObj.fromCheckoutScreen"
      class="mobile-full"
    >
      <mat-icon>add_shopping_cart</mat-icon>
      {{ "Orders.ProductDetail.ContinueButtonText" | translate }}
    </button>
    <button
      mat-stroked-button
      color="accent"
      (click)="onSubmitMoreItems()"
      *ngIf="CustomerProductDialogObj.fromCheckoutScreen"
      class="mobile-full"
    >
      <mat-icon>update</mat-icon>
      {{ "Orders.ProductDetail.UpdateButtonText" | translate }}
    </button>
    <span>&nbsp;</span>
    <button
      mat-stroked-button
      color="accent"
      (click)="onSubmitGoToCart()"
      *ngIf="!CustomerProductDialogObj.fromCheckoutScreen"
      class="mobile-full"
    >
      <mat-icon>shopping_cart</mat-icon>
      {{ "Orders.ProductDetail.CheckoutCartButtonText" | translate }}
    </button>
  </div>
</form>
