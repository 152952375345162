import { Component, OnInit } from '@angular/core';
import { UserService } from './core/authentication/user.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoaderService } from './core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from './core/config/app.config';
import { CustomLoggerService } from './logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  dynamicCSSUrl: string;
  constructor(private userService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private logger: CustomLoggerService,
  ) {

    router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    });
    // translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang(AppConfig.settings.defaultLanguage);
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }
  ngOnInit(): void {
    // this.logger.log('app.component AppComponent initialized.');
    this.dynamicCSSUrl = '/assets/css/dynamic.css'; // 'https://unifiedassets.s3.amazonaws.com/dynamic.css';
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loaderService.display(true);
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loaderService.display(false);
    }
  }
}
