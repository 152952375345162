<!-- <button mat-icon-button color="warn"  style="float: right;" (click)="onCancel()" matTooltip="{{confirmboxData.closeButtonTooltip}}">
  <mat-icon>cancel</mat-icon>
  </button> -->
  <h2 mat-dialog-title>{{ 'Common.CallForPrice.Title' | translate }}</h2>
  <mat-divider></mat-divider>
  <br />

  <div mat-dialog-content>
      <form class="example-form" [formGroup]="customerOrderForm">
    <div class="dialog-container">
      <mat-form-field style="width:100%;" *ngIf="customers && (customers!=null && customers?.length > 0)  ">
        <mat-select placeholder="{{ 'Common.CallForPrice.CustomerPlaceholder' | translate }}" formControlName="customerSelect" required (selectionChange)="onChangeCustomerSelection($event)"
          [(value)]="customerId" >
          <mat-option value="0">{{ 'Common.CallForPrice.CustomerDefaultOptionLabel' | translate }}</mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer._id">
            {{customer.customerMaster.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <!-- <div fxLayout="row"> -->
          <mat-card class="example-card" *ngIf="customerSelected && contactDetail">
              <mat-card-header>
                  <div mat-card-avatar class="example-header-image"> <mat-icon class="main-icon">account_balance</mat-icon></div>
                <mat-card-title>{{contactDetail.suppliername}}</mat-card-title>
                <mat-card-subtitle>{{contactDetail.supplierphone}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
<table><tr> <td><mat-icon class="second-icon">contacts</mat-icon> {{contactDetail.repname}} </td></tr>
  <tr> <td><mat-icon  class="second-icon">contact_mail</mat-icon> {{contactDetail.repemail}} </td></tr>
<tr><td><mat-icon class="second-icon">contact_phone</mat-icon> {{contactDetail.repphone}} </td></tr>
  <tr> <td><mat-icon class="second-icon">stay_primary_portrait</mat-icon> {{contactDetail.repmobilephone}} </td></tr></table>



              </mat-card-content>

            </mat-card>
      </div>
    <!-- </div> -->
  </form>
  </div>
  <br />
  <mat-divider></mat-divider>

  <div mat-dialog-actions style="height: 100%; flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-end; align-items: center;">
    <button mat-stroked-button color="warn" (click)="onCancel()" >
      <mat-icon>cancel</mat-icon> {{ 'Common.CallForPrice.CloseButtonText' | translate }}
    </button>


  </div>

