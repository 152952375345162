import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderModel } from '../models/order.model';
import { HttpService } from '../http/http.service';
import { CustomerProductModel } from '../models/customer-product.model';
import { CustomerModel } from '../models/customer.model';
import { CommitteOrder, OrderRequestModel } from '../models/order.request.model';
import { OrderAddResponseModel } from '../models/order-add-response.model';
import { AppConfig } from '../config/app.config';
import { HttpErrorModel } from '../models/http.error.model';
import { DomainsupplierkeyRequestModel } from '../models/domainsupplierkey.request.model';
import { DomainsupplierkeyResponseModel } from '../models/domainsupplierkey.response.model';
import { CustomerAddressModel } from '../models/customer-address.model';
import { OrderListRequestModel } from '../models/order-list.request.model';
import { CustomerDeliverytimeResponseModel } from '../models/customer-deliverytime.response.model';
import { CustomerContactDetailModel } from '../models/customer-contact-detail.model';
import { Category } from '../models/category';
import { ProductCategory } from '../models/productCategory';
import { CustomerUserSupplier, Order } from '../models/response';
import { LoginUserDto } from '../models/user';

@Injectable()
export class OrdersService {
  private awsUserId!: string;
  private baseUrl = AppConfig.settings.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      supplierkey: localStorage.getItem('supplierkey') || '',
    }),
  };
  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      supplierkey: AppConfig.settings.supplierkey,
    }),
  };

  httpOptions3 = {
    headers: new HttpHeaders({
      supplierkey: AppConfig.settings.supplierkey,
    }),
  };

  constructor(private httpService: HttpService) {}

  getOrders(
    orderStatus: string,
    orderDuration: string,
    range?: any
  ): Observable<Order[] | HttpErrorModel> {
    const orderListRequest: OrderListRequestModel = new OrderListRequestModel();
    orderListRequest.days = orderDuration;
    orderListRequest.orderstatus = orderStatus;
    orderListRequest.awsuserid = localStorage.getItem('aws') || '';
    if (range?.start && range?.end) {
      orderListRequest.startDate = new Date(range.start).toISOString();
      orderListRequest.endDate = new Date(range.end).toISOString();
    }
    return this.httpService.post<Order[]>(
      this.baseUrl + `order/orderbyUser`,
      JSON.stringify(orderListRequest),
      this.httpOptions
    );
  }

  getProductReport(
    orderStatus: string,
    orderDuration: string,
    range?: any
  ): Observable<any | HttpErrorModel> {
    const orderListRequest: OrderListRequestModel = new OrderListRequestModel();
    orderListRequest.days = orderDuration;
    orderListRequest.orderstatus = orderStatus;
    orderListRequest.awsuserid = localStorage.getItem('aws') || '';
    if (range?.start && range?.end) {
      orderListRequest.startDate = new Date(range.start).toISOString();
      orderListRequest.endDate = new Date(range.end).toISOString();
    }
    return this.httpService.post<any>(
      this.baseUrl + `order/product/report`,
      JSON.stringify(orderListRequest),
      this.httpOptions
    );
  }

  getOrder(orderid: string | null): Observable<Order | HttpErrorModel> {
    return this.httpService.get<OrderModel>(
      this.baseUrl + `order/getOrderByid/${orderid}`,
      this.httpOptions
    );
  }

  getcustomers(): Observable<CustomerUserSupplier[] | HttpErrorModel> {
    this.awsUserId = localStorage.getItem('aws') || '';
    let userid = localStorage.getItem('userId') || '';
    return this.httpService.post<CustomerUserSupplier[]>(
      this.baseUrl + 'customerusersupplier/allcustomer',
      `{ "awsuserid": "${this.awsUserId}","userid": "${userid}"}`,
      this.httpOptions
    );
  }

  getCategory(): Observable<Category[] | HttpErrorModel> {
    return this.httpService.get<Category[]>(
      this.baseUrl +
        'category/bysupplier/' +
        localStorage.getItem('supplierMasterId'),
      this.httpOptions
    );
  }

  getProductByCategory(
    id: number
  ): Observable<ProductCategory[] | HttpErrorModel> {
    this.awsUserId = localStorage.getItem('aws') || '';
    let userId = localStorage.getItem('userId') || '';
    const body = {
      supplierId: localStorage.getItem('supplierMasterId'),
      categoryId: id,
      awsuserid: this.awsUserId,
      userId:userId
    };
    // tslint:disable-next-line:max-line-length
    return this.httpService.post<ProductCategory[]>(
      this.baseUrl + 'productcustomersupplier/getProduct',
      JSON.stringify(body),
      this.httpOptions
    );
  }

  getCustomerProducts(
    id: string
  ): Observable<CustomerProductModel[] | HttpErrorModel> {
    const searchFilter =
      '?filter=customerid||eq||' +
      id +
      '&filter=supplierMasterId||eq||' +
      localStorage.getItem('supplierMasterId');
    return this.httpService.get<CustomerProductModel[]>(
      this.baseUrl + 'customerproduct' + searchFilter,
      this.httpOptions
    );
  }

  /*getCustomerRules(body: any): Observable<CustomerRuleModel | HttpErrorModel> {
      return this.httpService.post<CustomerRuleModel>(this.baseUrl + 'api/customer/customerrule',
      body, this.httpOptions);
    }*/
  getCustomercontactdetail(
    id: string
  ): Observable<CustomerContactDetailModel | HttpErrorModel> {
    return this.httpService.post<CustomerContactDetailModel>(
      this.baseUrl + 'customermaster/contactdetail',
      `{"customerid" : "${id}"}`,
      this.httpOptions
    );
  }

  getCustomerAddress(
    id: string
  ): Observable<CustomerAddressModel[] | HttpErrorModel> {
    return this.httpService.post<CustomerAddressModel[]>(
      this.baseUrl + 'customeraddress/getAddressByCustomer',
      `{"customerid" : "${id}"}`,
      this.httpOptions
    );
  }

  getCustomerDeliverytime(
    id: string,
    customeraddressid: string
  ): Observable<CustomerDeliverytimeResponseModel | HttpErrorModel> {
    return this.httpService.post<CustomerDeliverytimeResponseModel>(
      this.baseUrl + 'customeraddress/getDeliveryTime',
      `{"customerid" : "${id}", "customeraddressid" : "${customeraddressid}"}`,
      this.httpOptions
    );
  }

  addOrder(
    addOrderModel: OrderRequestModel
  ): Observable<OrderAddResponseModel | HttpErrorModel> {
    return this.httpService.post<OrderAddResponseModel>(
      this.baseUrl + 'order',
      JSON.stringify(addOrderModel),
      this.httpOptions
    );
  }

  updateOrder(data: any): Observable<OrderAddResponseModel | HttpErrorModel>  {
    return this.httpService.update<OrderAddResponseModel>(
      this.baseUrl + 'order/update',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  removeItemFromOrder(data: any): Observable<OrderAddResponseModel | HttpErrorModel>  {
    return this.httpService.update<OrderAddResponseModel>(
      this.baseUrl + 'order/item/update',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  committeOrder(
    addOrderModel: CommitteOrder
  ): Observable<OrderAddResponseModel | HttpErrorModel> {
    return this.httpService.update<OrderAddResponseModel>(
      this.baseUrl + 'order',
      JSON.stringify(addOrderModel),
      this.httpOptions
    );
  }

  validateSupplierKey(
    requestModel: DomainsupplierkeyRequestModel
  ): Observable<DomainsupplierkeyResponseModel | HttpErrorModel> {
    return this.httpService.post<DomainsupplierkeyResponseModel>(
      this.baseUrl + 'customerusersupplier/domainsupplierkey',
      JSON.stringify(requestModel),
      this.httpOptions2
    );
  }

  loginUser(requestModel: LoginUserDto): Observable<any | HttpErrorModel> {
    return this.httpService.post<any>(
      this.baseUrl + 'user/login',
      JSON.stringify(requestModel),
      this.httpOptions2
    );
  }
  changePassword(requestModel: any): Observable<any | HttpErrorModel> {
    return this.httpService.post<any>(
      this.baseUrl + 'user/changepassword',
      JSON.stringify(requestModel),
      this.httpOptions2
    );
  }
  
  SendEmail(formData: any): Observable<any | HttpErrorModel> {
    return this.httpService.post<any>(this.baseUrl + 'mail/send-email', formData, this.httpOptions3);  
  }
}
